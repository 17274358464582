import "../../styles/layout.css";
import "../../styles/component_styles/team.css";

export default function TeamText() {
  return (
    <div id="team" className="grid">

      <div className="name_container" id="Alec">
        <div className="inner_container">
          <h2 className="name">Alec Nossa</h2>
          <p className="bio">Co founder</p>
          
        </div>
      </div>

      <div className="name_container" id="Will">
        <div className="inner_container">
          <h2 className="name">Will Burstein</h2>
          <p className="bio">Co founder</p>
          
        </div>
      </div>
    </div>
  );
}
