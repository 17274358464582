import "../App.css";
import "../styles/text.css";
import "../styles/layout.css";
import ProjectText from "../components/text/projectText";

export default function Project() {
  return (
    <>
      <ProjectText />
    </>
  );
}
