import "../../styles/layout.css";
import "../../styles/component_styles/project.css";

export default function ProjectText() {
  return (
    <>
      <h2 id="intro">Colog™</h2>
      <div className="flex">
        <p className="paragraph">
          We are currently conducting a super secret beta at a small liberal
          arts college.
        </p>
        <p className="paragraph">
          Here is our <a className="pdf" id="privacy" href="/privacy_policy.pdf" target="_blank">privacy policy</a> and our <a className="pdf" id="terms" href="/terms_of_use.pdf" target="_blank">terms of service</a>.
        </p>
      </div>
    </>
  );
}
