import "../../styles/layout.css";
import "../../styles/component_styles/home.css";

export default function HomeText() {
  return (
    <>
      <div className="flex">
        <h2 id="hello">Hello</h2>
        <p className="paragraph" id="we">
          This is the Ness App LLC webpage. We're building another one, but its still in the works :)
        </p>
        <p className="paragraph" id="we">
       
        </p>
        <p className="paragraph" id="we">
          You can also contact us if you have any questions, concerns, inquiries, or inclinations to chit chat.
        </p>
      </div>
    </>
  );
}
