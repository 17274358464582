import "../App.css";
import "../styles/text.css";
import "../styles/layout.css";
import HomeText from "../components/text/homeText.jsx";

export default function Home() {
  return (
    <>
      <HomeText />
    </>
  );
}
