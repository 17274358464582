import "../styles/text.css";
import "../styles/layout.css";
import TeamText from "../components/text/teamText.jsx";

export default function Team() {
  return (
    <>
      <TeamText />
    </>
  );
}
