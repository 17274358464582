import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import "./styles/layout.css";
import App from "./App.js";
import reportWebVitals from "./reportWebVitals.js";
import Navbar from "./components/navbar/navbar.jsx";
import { BrowserRouter as Router } from "react-router-dom";

const rootContainer = document.getElementById("root");
const root = createRoot(rootContainer);

root.render(
  <React.StrictMode>
    <Router>
      <div id="app_root">
        <Navbar className="z_indexed" />
        <App />
        </div>
    </Router>
  </React.StrictMode>,
);

reportWebVitals();
