import '../../styles/component_styles/footer.css';
import '../../styles/text.css'


const Footer = () => {
    return (
        <footer className='footer'>
            <div id="left">  
            </div>
            <div id="right">
                <p id="company" className="footer_text">© Ness App LLC 2024</p>
                </div>
        </footer>
    )
}

export default Footer;