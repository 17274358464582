import "../styles/component_styles/page404.css";


const Page404 = () => {
  return (
    <>
      <div id="not_found_container">
      <img id="cherub" src="/imgs/lazy.png" alt="Lazy" />
        <h1 id="not_found">404</h1>
        <p>Ugh...</p>
        <p>congratulations! You found the hidden cherub</p>
      </div>
    </>
  );
};

export default Page404;
