import "../styles/text.css";
import "../styles/layout.css";
import Input from "../components/input/input.jsx";
import "../styles/component_styles/input.css";
import { ToastContainer } from "react-toastify";

export default function Contact() {
  return (
    <>
      <p id="contact_welcome"></p>
      <div id="contact_container">
        <ToastContainer />
        <Input/>
      </div>
    </>
  );
}
