import "../../styles/component_styles/input.css";
import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './../../firebaseConfig.js';
import { collection, addDoc, getFirestore} from "firebase/firestore";

const Input = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const db = getFirestore();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitted(true);
    const currentDate = new Date();
    const sanatizer = (str) => {
      return str.replace(/<[^>]*>?/gm, "");
    }

    const santizedEmail = sanatizer(email);
    const santizedName = sanatizer(name);
    const santizedCompany = sanatizer(company);
    const santizedMessage = sanatizer(message);
    
    const formData = { 
      name: santizedName,
      email: santizedEmail,
      company: santizedCompany,
      message: santizedMessage, 
      date: currentDate };
    try {

  
      const docRef = await addDoc(collection(db, "messages"), formData);
      console.log("Document written with ID: ", docRef.id);
  
      toast.success("Message received", {
        className: "toast-success",
        bodyClassName: "toast_body",
        hideProgressBar: true,
        icon: false,
      });
  
      setEmail("");
      setName("");
      setCompany("");
      setMessage("");
    } catch (error) {
      console.error("Error adding document: ", error);
  
      toast.error("Error sending message", {
        className: "toast-error",
        bodyClassName: "toast_body",
        hideProgressBar: true,
        icon: false,
      });
    }
  };
  return (
    <form id="form" onSubmit={handleSubmit}>
      <input
        id="name"
        type="text"
        value={name}
        placeholder="Name*"
        required
        className={submitted && !name ? "input-error" : ""}
        onChange={(e) => setName(e.target.value)}
      />
      <input
        id="company_input"
        type="text"
        value={company}
        placeholder="Company"
        className={submitted && !name ? "input-error" : ""}
        onChange={(e) => setCompany(e.target.value)}
      />
      <input
        id="email_input"
        type="email"
        value={email}
        placeholder="Email*"
        required
        className={submitted && !name ? "input-error" : ""}
        onChange={(e) => setEmail(e.target.value)}
      />
      <textarea
        id="message"
        value={message}
        placeholder="Message*"
        required
        className={submitted && !name ? "input-error" : ""}
        onChange={(e) => setMessage(e.target.value)}
      />
      <button 
        id="submit" 
        type="submit"
        >
        Submit
      </button>
    </form>
  );
};
export default Input;
