import { Routes, Route } from "react-router-dom";
import Home from './pages/home.jsx';
import Project from './pages/project.jsx';
import Contact from './pages/contact.jsx';
import Team from './pages/team.jsx';
import Page404 from "./pages/Page404.jsx";
import Footer from "./components/footer/footer.jsx";


function App() {
  return (
    <div className="App">
      <div className="body_container">
        <div className="textContainer">

            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/project" element={<Project />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/team" element={<Team />} />
              <Route path="*" element={<Page404 />} />
            </Routes>

        </div> 
        <Footer /> 
      </div>
      
    </div>
    
  );
}

export default App;