import { Link } from "react-router-dom";
import * as Toggle from "@radix-ui/react-toggle";
import { useState, useEffect } from "react";
import "../../styles/component_styles/navbar.css";

export default function Navbar() {
  const [activeLink, setActiveLink] = useState(
    localStorage.getItem("activeLink") || "/",
  );
  const [color, setColor] = useState("white");
  console.log(color);
  useEffect(() => {
    const storedColog = localStorage.getItem("color");
    if (storedColog) {
      setColor(storedColog);
      document.documentElement.style.setProperty(
        "--primary-color-two",
        storedColog,
      );
    }
  }, []);

  const changeColor = (newColor) => {
    setColor(newColor);
    document.documentElement.style.setProperty("--primary-color-two", newColor);
    localStorage.setItem("color", newColor);
  };

  useEffect(() => {
    localStorage.setItem("activeLink", activeLink);
  }, [activeLink]);

  return (
    <>
      <div id="header">
        <div id="ness_box">
          <div id="ness_logo">
            <h1 id="ness">Ness</h1>
            <h1 id="app">App</h1>
          </div>
        </div>
        <div id="nav_container">
          <nav className="nav">
            <Link
              id="home"
              className="nav_items"
              to="/"
              onClick={() => {
                setActiveLink("/");
                changeColor("#FFDFC5");
              }}
            >
              <Toggle.Root
                className="Toggle"
                data-state={activeLink === "/" ? "on" : "off"}
              >
                Home
              </Toggle.Root>
            </Link>
            <Link
              id="colog"
              className="nav_items"
              to="/project"
              onClick={() => {
                setActiveLink("/project");
                changeColor("lightblue");
              }}
            >
              <Toggle.Root
                className="Toggle"
                data-state={activeLink === "/project" ? "on" : "off"}
              >
                Colog
              </Toggle.Root>
            </Link>
            <Link
              id="team"
              className="nav_items"
              to="/team"
              onClick={() => {
                setActiveLink("/team");
                changeColor("rgb(176, 238, 176)");
              }}
            >
              <Toggle.Root
                className="Toggle"
                data-state={activeLink === "/team" ? "on" : "off"}
              >
                Team
              </Toggle.Root>
            </Link>
            <Link
              id="contact"
              className="nav_items"
              to="/contact"
              onClick={() => {
                setActiveLink("/contact");
                changeColor("rgb(199, 165, 242)");
              }}
            >
              <Toggle.Root
                className="Toggle"
                data-state={activeLink === "/contact" ? "on" : "off"}
              >
                Contact
              </Toggle.Root>
            </Link>
          </nav>
        </div>
        <div id="right_box"></div>
      </div>
    </>
  );
}
